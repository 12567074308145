<template>
  <div class="d-flex flex-column flex-root"></div>
</template>

<script>
export default {
  name: "Logout",
  mounted() {
    this.$router.push("compose");
  },
};
</script>
